import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AccountState } from '../types'

const initialState: AccountState = {
	token: '',
	isLoggedIn: false,
	isInitialized: false,
	user: null,
	apiAuthError: null,
}

const accountSlice = createSlice({
	name: 'account',
	initialState,
	reducers: {
		accountInitialize: (
			state,
			action: PayloadAction<{ isLoggedIn: boolean; user: any; token: string }>,
		) => {
			state.isLoggedIn = action.payload.isLoggedIn
			state.isInitialized = true
			state.token = action.payload.token
			state.user = action.payload.user
		},
		// Handles user login
		login: (state, action: PayloadAction<{ user: any; token: string }>) => {
			state.isLoggedIn = true
			state.user = action.payload.user
			state.token = action.payload.token
		},
		// Handles user logout
		logout: (state) => {
			state.isLoggedIn = false
			state.user = null
			state.token = ''
		},
	},
})

// Export actions
export const { accountInitialize, login, logout } = accountSlice.actions

// Export reducer
export default accountSlice.reducer
