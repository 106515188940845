import React, { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { AppState } from '../../store/types'
import { RootState } from '../../store/index'

interface GuestGuardProps {
	children: ReactNode
}

function GuestGuard({ children }: GuestGuardProps) {
	const isLoggedIn = useSelector(
		(state: RootState) => state.account?.isLoggedIn ?? false,
	)

	if (isLoggedIn) {
		return <Redirect to="/dashboard" />
	}

	return children
}

export default GuestGuard
