import {
	AccountInitializeAction,
	LoginAction,
	LogoutAction,
	User,
} from './types'

// Define action constants for account management
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const ACCOUNT_INITIALIZE = 'ACCOUNT_INITIALIZE'
export const SET_API_AUTH_ERROR = 'SET_API_AUTH_ERROR'

// Define action constants for customization
export const MENU_OPEN = '@customization/MENU_OPEN'
export const SET_MENU = '@customization/SET_MENU'
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY'
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS'

export const accountInitialize = (
	isLoggedIn: boolean,
	user: any,
	token: string,
): AccountInitializeAction => ({
	type: 'ACCOUNT_INITIALIZE',
	payload: { isLoggedIn, user, token },
})

export const login = (user: any, token: string): LoginAction => ({
	type: 'LOGIN',
	payload: { user, token },
})

export const logout = (): LogoutAction => ({
	type: 'LOGOUT',
})

// Define action creators for account actions
export const loginAction = () => ({
	type: LOGIN,
})

export const logoutAction = () => ({
	type: LOGOUT,
})

export const accountInitializeAction = () => ({
	type: ACCOUNT_INITIALIZE,
})

export const setApiAuthErrorAction = (error: string) => ({
	type: SET_API_AUTH_ERROR,
	payload: { error },
})

// Define action creators for customization actions
// Assuming MENU_OPEN should open a menu with a specific ID
export const menuOpenAction = (id: string[]) => ({
	type: MENU_OPEN,
	id,
})

export const setMenuAction = (opened: boolean) => ({
	type: SET_MENU,
	opened,
})

export const setFontFamilyAction = (fontFamily: string) => ({
	type: SET_FONT_FAMILY,
	fontFamily,
})

export const setBorderRadiusAction = (borderRadius: number) => ({
	type: SET_BORDER_RADIUS,
	borderRadius,
})

// Define TypeScript types for actions
export type AccountActionTypes =
	| { type: typeof LOGIN }
	| { type: typeof LOGOUT }
	| { type: typeof ACCOUNT_INITIALIZE }
	| { type: 'SET_API_AUTH_ERROR'; error: string }

export type CustomizationActionTypes =
	| { type: typeof MENU_OPEN; id: string[] }
	| { type: typeof SET_MENU; opened: boolean }
	| { type: typeof SET_FONT_FAMILY; fontFamily: string }
	| { type: typeof SET_BORDER_RADIUS; borderRadius: number }

// Create a union type of all action types in the application
export type AppActionTypes = AccountActionTypes | CustomizationActionTypes
