import { useState, useEffect } from 'react'
import axios from 'axios'
import { BASE_URL } from '../config'

type Course = {
	id: number
	courseName: string
	courseOlId: number
}

interface CertificateData {
	id: number
	certificateName: string
	certificateIssuer: string
	certificateCourses: Course[]
	minimumMasteryScore: number
	expiryPeriod: number
}

// Utility function to get the authentication token
const getAuthToken = (tokenName: string): string => {
	const token = localStorage.getItem(tokenName)
	if (tokenName === 'sessionToken') {
		return token || 'defaultSessionTokenValue'
	}
	return `Bearer ${token}`
}

const useCertificateDataFetcher = (id: string, tokenName: string) => {
	const [certificateData, setCertificateData] =
		useState<CertificateData | null>(null)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [error, setError] = useState<string | null>(null)

	useEffect(() => {
		const fetchCertificateData = async () => {
			setIsLoading(true)
			setError(null)
			try {
				// Use the utility function to get the auth token
				const authorizationToken = getAuthToken(tokenName)

				const response = await axios.get<CertificateData>(
					`${BASE_URL}/certificates/${id}`,
					{
						headers: {
							Authorization: authorizationToken,
						},
					},
				)
				setCertificateData(response.data)
			} catch (err) {
				setError(err instanceof Error ? err.message : String(err))
				console.error('Error fetching certificate data:', err)
			} finally {
				setIsLoading(false)
			}
		}

		if (id) {
			fetchCertificateData()
		}
	}, [id, tokenName])

	return { certificateData, isLoading, error }
}

export default useCertificateDataFetcher
