import React from 'react'
import { Typography, Box, CircularProgress, Alert } from '@material-ui/core'
import useAxiosFetch from '../../../../hooks/useFetch'
import NavGroup from './NavGroup'
import getAdminMenu from '../../../../menu-items/admin'
import getUserMenu from '../../../../menu-items/user'
import getSupervisorMenu from '../../../../menu-items/supervisor'
import SessionExpiredDialog from '../../../../ui-component/session-expired-dialog'
import { BASE_URL } from '../../../../config'
import { MenuItem } from './types'
import NavItem from './NavItem'

type UserData = {
	role: string
}

function MenuList() {
	const emailValue = localStorage.getItem('email') || ''

	const {
		data: userData,
		sessionExpired,
		isLoading,
		fetchError,
	} = useAxiosFetch(`${BASE_URL}/users/email?email=${emailValue}`, 'token')

	function getMenuItems(role: string | undefined): MenuItem {
		if (role === 'admin') {
			return getAdminMenu()
		}
		if (role === 'supervisor') {
			return getSupervisorMenu()
		}
		return getUserMenu()
	}

	if (isLoading) {
		return (
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					m: 2,
				}}
			>
				<CircularProgress />
				<Typography variant="h6" color="textSecondary" sx={{ mt: 2 }}>
					Loading menu...
				</Typography>
			</Box>
		)
	}

	if (fetchError) {
		return (
			<Alert severity="error">Error loading menu items: {fetchError}</Alert>
		)
	}

	const user = userData as unknown as UserData
	const userRole = user?.role
	const items = getMenuItems(userRole)

	const renderNavItems = (menuItem: MenuItem) => {
		if (menuItem.children) {
			return menuItem.children.map((child) => {
				switch (child.type) {
					case 'group':
						return <NavGroup key={child.id} item={child} />
					case 'item':
						return <NavItem key={child.id} item={child} level={1} />
					default:
						return (
							<Typography
								key={child.id}
								variant="h6"
								color="error"
								align="center"
							>
								Menu Items Error
							</Typography>
						)
				}
			})
		}

		return null
	}

	return (
		<>
			<SessionExpiredDialog hasSessionExpired={sessionExpired} />
			{renderNavItems(items)}
		</>
	)
}

export default MenuList
