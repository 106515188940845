import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import config from '../../config'
import { CustomizationState } from '../types'

// Define the initial state using the initialState type
const initialState: CustomizationState = {
	isOpen: [],
	fontFamily: config.fontFamily,
	borderRadius: config.borderRadius,
	opened: true,
}

const customizationSlice = createSlice({
	name: 'customization',
	initialState,
	reducers: {
		menuOpen(state, action: PayloadAction<string[]>) {
			state.isOpen = action.payload
		},
		setMenu(state, action: PayloadAction<boolean>) {
			state.opened = action.payload
		},
		setFontFamily(state, action: PayloadAction<string>) {
			state.fontFamily = action.payload
		},
		setBorderRadius(state, action: PayloadAction<number>) {
			state.borderRadius = action.payload
		},
	},
})

// Export actions
export const { menuOpen, setMenu, setFontFamily, setBorderRadius } =
	customizationSlice.actions

// Export reducer
export default customizationSlice.reducer
