import getAdminMenu from './admin'
import getUserMenu from './user'
import getSupervisorMenu from './supervisor'

type UserData = {
	role?: 'admin' | 'supervisor' | 'user'
	[key: string]: any
}

const showNav: UserData = JSON.parse(localStorage.getItem('userData') || '{}')

let items
if (showNav?.role === 'supervisor') {
	items = getSupervisorMenu()
} else if (showNav?.role === 'admin') {
	items = getAdminMenu()
} else {
	items = getUserMenu()
}

const menuItems = {
	items: [items],
}

export default menuItems
