/* eslint-disable */

import React from 'react'
import logo from './../assets/images/logo.png'
// material-ui
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from './../../assets/images/logo-dark.svg';
 * import logo from './../../assets/images/logo.svg';
 *
 */

//-----------------------|| LOGO SVG ||-----------------------//

const Logo = () => {
	return (
		<img
			src={logo}
			style={{
				borderRadius: 100,
			}}
			alt="Berry"
			height={'50'}
		/>
	)
}

export default Logo
