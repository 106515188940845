import React from 'react'
import { Box, Typography, IconButton, SxProps } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close' // Import the Close icon

type LabelTagState = 'active' | 'disabled' | 'warning'

const stateToStyles: Record<LabelTagState, SxProps> = {
	active: { backgroundColor: '#b29962', color: 'white' },
	disabled: { backgroundColor: 'gray', color: 'black' },
	warning: { backgroundColor: 'orange', color: 'black' },
}

type LabelTagProps = {
	children: React.ReactNode
	state?: LabelTagState
	isSelectable?: boolean
	onClose?: (identifier: string) => void
	identifier?: string
}

function LabelTag({
	children,
	state = 'active',
	isSelectable = false,
	onClose,
	identifier,
}: LabelTagProps) {
	const styles = stateToStyles[state]

	const handleOnClose = () => {
		if (isSelectable && onClose && identifier) {
			onClose(identifier)
		}
	}

	return (
		<Box
			sx={{
				...styles,
				borderRadius: 6,
				px: 2,
				py: 1,
				width: 'fit-content',
				fontWeight: 600,
				display: 'flex',
				alignItems: 'center',
			}}
		>
			<Typography>{children}</Typography>
			{isSelectable && (
				<IconButton size="small" onClick={handleOnClose} sx={{ ml: 1 }}>
					<CloseIcon fontSize="small" sx={{ color: '#fafafa' }} />
				</IconButton>
			)}
		</Box>
	)
}

export default LabelTag
