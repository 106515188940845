import React, { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { AppState } from '../../store/types'
import { RootState } from '../../store/index'

interface AuthGuardProps {
	children: ReactNode
}

function AuthGuard({ children }: AuthGuardProps) {
	const isLoggedIn = useSelector(
		(state: RootState) => state.account?.isLoggedIn ?? false,
	)

	if (!isLoggedIn) {
		return <Redirect to="/login" />
	}

	// Directly return children without using a fragment
	return children
}

export default AuthGuard
