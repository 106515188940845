import { useState, useEffect, useCallback } from 'react'
import axios, { AxiosResponse } from 'axios'

const getAuthToken = (tokenName: string): string => {
	const sessionToken =
		localStorage.getItem('sessionToken') ||
		'e6d36813cd6664073163f21a22ab24106b56f0d517ca2ca37e33918c74a162be'
	const token = localStorage.getItem(tokenName)

	if (tokenName === 'sessionToken') {
		return sessionToken
	}
	if (tokenName === 'token') {
		return `Bearer ${token}`
	}
	return sessionToken
}

type FetchResult<T> = {
	data: T[]
	fetchError: string | null
	isLoading: boolean
	sessionExpired?: boolean
	refetch?: () => void
}

const useAxiosFetch = <T>(
	dataUrl: string,
	tokenName: string,
): FetchResult<T> => {
	const [data, setData] = useState<T[]>([])
	const [fetchError, setFetchError] = useState<string | null>(null)
	const [sessionExpired, setSessionExpired] = useState(false)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [trigger, setTrigger] = useState(0)

	const refetch = useCallback(() => {
		setTrigger((prev) => prev + 1)
	}, [])

	useEffect(() => {
		let isMounted = true
		const source = axios.CancelToken.source()

		const paginatedUrl = dataUrl

		const fetchData = async () => {
			setIsLoading(true)
			const authToken = getAuthToken(tokenName)
			console.log(`Fetching data from ${paginatedUrl} with token ${authToken}`)
			try {
				const response: AxiosResponse<T[]> = await axios.get(paginatedUrl, {
					cancelToken: source.token,
					headers: { Authorization: getAuthToken(tokenName) },
				})

				if (isMounted) {
					setData(response.data)
					setFetchError(null)
				}
			} catch (err: any) {
				if (isMounted) {
					if (err.response && err.response.status === 401) {
						setSessionExpired(true)
					}
					setFetchError(err?.response?.data?.message)
					setData([])
				}
			} finally {
				if (isMounted) {
					setIsLoading(false)
				}
			}
		}

		fetchData()

		const cleanUp = () => {
			isMounted = false
			source.cancel()
		}

		return cleanUp
	}, [dataUrl, tokenName, trigger])

	return { data, fetchError, isLoading, sessionExpired, refetch }
}

export default useAxiosFetch
