/* eslint-disable */

import PropTypes from 'prop-types'
import React, { ReactNode } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'

import { Card, CardContent, CardHeader, Divider, Box } from '@material-ui/core'

// constant
const headerSX = {
	'& .MuiCardHeader-action': { mr: 0 },
}

type MainCardProps = {
	border?: boolean
	boxShadow?: boolean
	children?: ReactNode
	content?: boolean
	contentClass?: string
	contentSX?: object
	darkTitle?: boolean
	secondary?: ReactNode
	shadow?: string
	sx?: object
	title?: ReactNode
	midTitle?: ReactNode
}

const MainCard = React.forwardRef<HTMLDivElement, MainCardProps>(
	(
		{
			border = true,
			boxShadow,
			children,
			content = true,
			contentClass,
			contentSX,
			darkTitle,
			secondary,
			shadow,
			sx = {},
			title,
			midTitle = null,
			...others
		},
		ref,
	) => {
		const theme = useTheme()

		return (
			<Card
				ref={ref}
				{...others}
				sx={{
					border: border ? '1px solid' : 'none',
					borderColor: theme.palette.primary[200] + 75,
					':hover': {
						boxShadow: boxShadow
							? shadow
								? shadow
								: '0 2px 14px 0 rgb(32 40 45 / 8%)'
							: 'inherit',
					},
					...sx,
				}}
			>
				{(title || midTitle) && (
					<CardHeader
						sx={headerSX}
						title={
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
								}}
							>
								<Box
									sx={{
										flex: 1,
										textAlign: 'left',
										position: 'relative',
									}}
								>
									{title}
								</Box>
								<Box
									sx={{
										left: '55%',
										transform: 'translateX(-50%)',
										textAlign: 'center',
										position: 'absolute',
									}}
								>
									{midTitle}
								</Box>
							</Box>
						}
						action={secondary}
					/>
				)}

				{/* content & header divider */}
				{title && <Divider />}

				{/* card content */}
				{content && (
					<CardContent sx={contentSX} className={contentClass}>
						{children}
					</CardContent>
				)}
				{!content && children}
			</Card>
		)
	},
)

export default MainCard
