/* eslint-disable */

import React from 'react'
import ReactDOM from 'react-dom'

// third party
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

// project imports
import { store, persistor } from './store'
import * as serviceWorker from './serviceWorker'
import App from './App'
import config from './config'

// style + assets
import './assets/scss/style.scss'

//-----------------------|| REACT DOM RENDER  ||-----------------------//

ReactDOM.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<BrowserRouter basename={config.basename}>
				<App />
			</BrowserRouter>
		</PersistGate>
	</Provider>,
	document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
