import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import { RootState } from '../../store/index'
import { logout } from '../../store/slices/accountSlice'

type SessionExpiredDialogProps = {
	hasSessionExpired?: boolean
}

function SessionExpiredDialog({
	hasSessionExpired,
}: SessionExpiredDialogProps) {
	const [open, setOpen] = useState(false)
	const dispatch = useDispatch()
	const history = useHistory()
	const apiAuthError = useSelector(
		(state: RootState) => state.account.apiAuthError,
	)

	useEffect(() => {
		if (apiAuthError === 'Unauthorized' || hasSessionExpired) {
			setOpen(true)
		}
	}, [apiAuthError, hasSessionExpired])

	const handleLogout = () => {
		dispatch(logout())
		localStorage.clear()
		setOpen(false)
		history.push('/login')
	}

	// const handleClose = () => {
	// 	setOpen(false)
	// }

	return (
		<Dialog
			open={open}
			// onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">Session Expired</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					Your session has expired due to inactivity. Please log in again to
					continue.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleLogout} color="primary" autoFocus>
					Go to Login
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default SessionExpiredDialog
